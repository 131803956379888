<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Bauversicherung" [titleOnly]="inMail">
      Jedes Bauvorhaben hat seine Herausforderungen. Mit unserer <strong>Bauversicherung</strong> schützen Sie Ihr
      Unternehmen und decken Ihre Bauprojekte umfassend ab. Ganz egal, ob Sie umbauen, neu bauen oder renovieren.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance construction" [titleOnly]="inMail">
      À chaque projet de construction ses défis. Avec notre <strong>assurance construction</strong>, vous protégez votre
      entreprise et bénéficiez d’une couverture complète pour vos projets de construction, qu’il s’agisse d’une
      transformation, d'une nouvelle construction ou d’une rénovation.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione costruzioni" [titleOnly]="inMail">
      Ogni progetto di costruzione presenta dei rischi. La nostra <strong>assicurazione costruzioni</strong> tutela la
      vostra impresa offrendovi una copertura completa per i progetti di costruzione, che si tratti di trasformazioni,
      ristrutturazioni o nuove costruzioni.
    </app-result-item>
  </app-translation>
</nx-accordion>
