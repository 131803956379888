import { NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";
import { FormStepComponent } from "src/app/modules/form-step/form-step.component";
import { QuestionComponent } from "src/app/modules/question/question.component";

@Component({
  selector: "app-step1",
  standalone: true,
  imports: [
    FormStepComponent,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    ReactiveFormsModule,
    NxButtonModule,
    NxProgressStepperModule,
    QuestionComponent,
    NgTemplateOutlet,
  ],
  templateUrl: "./step1.component.html",
  styleUrl: "./step1.component.css",
})
export class Step1Component {
  @Input() inMail = false;
  needformFormGroup;

  constructor(calculator: NeedformCalculatorService) {
    this.needformFormGroup = calculator.getFormGroup();
  }
}
