<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Unfallversicherung" [titleOnly]="inMail">
      Ihren Angestellten kann immer etwas passieren. Und das kann für alle Beteiligten teuer werden. Deshalb ist eine
      Unfallversicherung in der Schweiz obligatorisch für jeden Arbeitgebenden. Sie kommt bei Unfällen im und ausserhalb
      des Betriebes auf. Die obligatorische <strong>Unfallversicherung</strong> deckt maximal 80 Prozent des letzten
      Lohnes ab. Diese Lücke schliesst unsere UVG-Zusatzversicherung. Damit man nicht auch noch finanziell kürzertreten
      muss.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance-accidents" [titleOnly]="inMail">
      Votre personnel n’est pas à l’abri d’un accident. Et les coûts peuvent alors être élevés pour l’ensemble des
      parties concernées. Tout employeur en Suisse a donc l’obligation de conclure une assurance-accidents, pour les
      accidents dans l’entreprise et à l’extérieur de l'entreprise. Cette <strong>assurance-accidents</strong>
      obligatoire couvre au maximum 80% du dernier salaire. Les 20% restants peut être comblés grâce à notre assurance
      complémentaire LAA, afin d’éviter des répercussions financières supplémentaires.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione contro gli infortuni" [titleOnly]="inMail">
      Ai vostri dipendenti può sempre capitare qualcosa, e le conseguenze di un infortunio possono essere salate per
      tutti. Per questo in Svizzera l’assicurazione contro gli infortuni è obbligatoria e copre gli infortuni sul lavoro
      e nel tempo libero. In caso di sinistro, l’<strong>assicurazione infortuni</strong> obbligatoria copre l’ultimo
      salario fino all’80% al massimo. La nostra assicurazione complementare LAINF colma questa lacuna, impedendovi di
      rimetterci anche economicamente.
    </app-result-item>
  </app-translation>
</nx-accordion>
