import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
  selector: "app-translation",
  standalone: true,
  imports: [],
  templateUrl: "./translation.component.html",
  styleUrl: "./translation.component.css",
})
export class TranslationComponent implements OnInit, OnDestroy {
  currentLanguage!: string;
  @Input({ alias: "lang", required: true }) contentLanguage!: string;
  private langChangeSubscribtion?: Subscription;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.langChangeSubscribtion = this.translateService.onLangChange.subscribe(
      (event) => {
        this.currentLanguage = event.lang;
      },
    );
    this.currentLanguage = this.translateService.currentLang;
  }

  ngOnDestroy(): void {
    this.langChangeSubscribtion?.unsubscribe();
  }
}
