import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ResultItemComponent } from "../../result-item/result-item.component";

@Component({
  selector: "app-base-insurance",
  standalone: true,
  imports: [],
  template: "",
})
export abstract class BaseInsuranceComponent implements AfterViewInit {
  @Input() optional = false;
  @Input() inMail = false;
  @ViewChild("content") content?: ElementRef;
  @ViewChild(ResultItemComponent) resultItem?: ResultItemComponent;

  ngAfterViewInit(): void {
    if (this.resultItem && !this.optional) {
      this.resultItem.expanded = true;
    }
  }
}
