<ng-template #questions>
  <ng-container [formGroup]="needformFormGroup">
    <ng-container formGroupName="employeesActivity">
      <app-question path="employeesActivity.noOfEmployees" [inMail]=inMail>
        <ng-container question>
          Wie viele Mitarbeitenden sind in Ihrem Unternehmen, Sie
          eingeschlossen?
        </ng-container>
        <nx-formfield label="Anzahl Mitarbeitende" appearance="outline" floatLabel="always">
          <nx-dropdown formControlName="noOfEmployees">
            <nx-dropdown-item value="1">{{'form_answers.1' | translate}}</nx-dropdown-item>
            <nx-dropdown-item value="2-9">{{'form_answers.2-9' | translate}}</nx-dropdown-item>
            <nx-dropdown-item value="10-49">{{'form_answers.10-49' | translate}}</nx-dropdown-item>
            <nx-dropdown-item value="50-249">{{'form_answers.50-249' | translate}}</nx-dropdown-item>
            <nx-dropdown-item value="250_more">{{'form_answers.250_more' | translate}}</nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </app-question>
      <app-question path="employeesActivity.wagesAmount" [inMail]=inMail>
        <ng-container question>
          Wie hoch war die Gesamtsumme an Löhnen, die Sie letztes Jahr ausbezahlt haben?
        </ng-container>
        <nx-radio-group name="wagesAmount" formControlName="wagesAmount">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,4"><nx-radio value="less_100k">{{'form_answers.less_100k' | translate}}</nx-radio></div>
              <div nxCol="12,4"><nx-radio value="100k_to_1mil">{{'form_answers.100k_to_1mil' | translate}}</nx-radio>
              </div>
              <div nxCol="12,4"><nx-radio value="1mil_more">{{'form_answers.1mil_more' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="employeesActivity.turnover" [inMail]=inMail>
        <ng-container question>
          Wie hoch war Ihr provisorischer Umsatz im letzten Jahr?
        </ng-container>
        <nx-radio-group name="turnover" formControlName="turnover">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,4"><nx-radio value="less_100k">{{'form_answers.less_100k' | translate}}</nx-radio></div>
              <div nxCol="12,4"><nx-radio value="100k_to_1mil">{{'form_answers.100k_to_1mil' | translate}}</nx-radio>
              </div>
              <div nxCol="12,4"><nx-radio value="1mil_more">{{'form_answers.1mil_more' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
    </ng-container>
  </ng-container>
</ng-template>
@if(!inMail) {
<div>
  <app-form-step icon="product-people-collaboration">
    <ng-container *ngTemplateOutlet="questions"></ng-container>
    <div class="action-buttons">
      <button nxButton="secondary" role="button" nxStepperPrevious>
        Zurück
      </button>
      <button nxButton role="button" nxStepperNext>Weiter</button>
    </div>
  </app-form-step>
</div>
} @else {
<ng-container *ngTemplateOutlet="questions"></ng-container>
}
