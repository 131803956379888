@if (titleOnly) {
{{title}}
} @else {
<nx-expansion-panel [expanded]="expanded" class="result-item">
  <nx-expansion-panel-header>
    <nx-expansion-panel-title>
      {{ title }}
    </nx-expansion-panel-title>
  </nx-expansion-panel-header>
  <div class="result-item-content">
    <ng-content></ng-content>
  </div>
</nx-expansion-panel>
}
