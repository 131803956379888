import { NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";

@Component({
  selector: "app-question",
  standalone: true,
  imports: [NgTemplateOutlet, TranslateModule],
  templateUrl: "./question.component.html",
  styleUrl: "./question.component.scss",
})
export class QuestionComponent {
  @Input() inMail = false;
  @Input() path = "";
  @Input() translateAnswer = true;

  constructor(private calculator: NeedformCalculatorService) {}

  get answer(): string {
    const path = this.path.split(".");
    const formgroup = this.calculator.getFormGroup();
    // TODO: Find better way to type this function part so it can be type checked
    // eslint-disable-next-line
    let currentFGValue: any = formgroup.value;
    let currentPath;
    while ((currentPath = path.shift()) !== undefined) {
      if (!currentFGValue) return "";
      if (currentFGValue && !currentFGValue[currentPath]) {
        return "";
      }
      currentFGValue = currentFGValue[currentPath];
    }

    if (
      currentFGValue &&
      typeof currentFGValue !== "object" &&
      typeof currentFGValue !== "function"
    ) {
      return currentFGValue.toString();
    }

    return "";
  }
}
