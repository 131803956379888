<div #self nxLayout="grid maxwidth" class="nx-margin-top-2m">
  <form [formGroup]="needformFormGroup">
    <nx-single-stepper currentStepLabel="" nextLabel="" (selectionChange)="stepSelectionChange($event)">
      <nx-step label="Grundangaben Firma">
        <app-step1></app-step1>
      </nx-step>
      <nx-step label="Mitarbeitende & Tätigkeit">
        <app-step2></app-step2>
      </nx-step>
      <nx-step label="Standort & Ausstattung">
        <app-step3 (submit)="submitForm()"></app-step3>
      </nx-step>
    </nx-single-stepper>
  </form>
</div>
