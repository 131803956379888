<ng-template #questions>
  <ng-container [formGroup]="needformFormGroup">
    <ng-container formGroupName="locationEquipment">
      <app-question path="locationEquipment.doTransportGoods" [inMail]=inMail>
        <ng-container question>
          Transportieren Sie Waren oder lassen Sie Waren transportieren?
        </ng-container>
        <nx-radio-group name="doTransportGoods" formControlName="doTransportGoods">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.rentOrOwn" [inMail]=inMail>
        <ng-container question>
          Bezahlen Sie an Ihrem Standort Miete oder ist Ihr Unternehmen Eigentümer des Standorts?
        </ng-container>
        <nx-radio-group name="rentOrOwn" formControlName="rentOrOwn">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="rent">{{'form_answers.rent' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="own">{{'form_answers.rent' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.overSixVehichles" [inMail]=inMail>
        <ng-container question>
          Verfügen Sie über mehr als 6 Fahrzeuge (Firmenfahrzeuge)?
        </ng-container>
        <nx-radio-group name="overSixVehichles" formControlName="overSixVehichles">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.furnitured" [inMail]=inMail>
        <ng-container question>
          Haben Sie mehr Mobiliar als einen Tisch und einen Stuhl?
        </ng-container>
        <nx-radio-group name="furnitured" formControlName="furnitured">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.hasMachinery" [inMail]=inMail>
        <ng-container question>
          Beinhaltet Ihre Einrichtung Bürotechnik, Maschinen oder Anlagen?
        </ng-container>
        <nx-radio-group name="hasMachinery" formControlName="hasMachinery">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.furnitureValue" [inMail]=inMail>
        <ng-container question>
          Übersteigen die Einrichtungsgegenstände, den Gesamtwert von CHF 5 Mio.?
        </ng-container>
        <nx-radio-group name="furnitureValue" formControlName="furnitureValue">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
      <app-question path="locationEquipment.locationExpansion" [inMail]=inMail>
        <ng-container question>
          Planen Sie demnächst einen Umbau, einen Neubau oder eine Renovation Ihres Standorts?
        </ng-container>
        <nx-radio-group name="locationExpansion" formControlName="locationExpansion">
          <div nxLayout="grid nopadding">
            <div nxRow>
              <div nxCol="12,6"><nx-radio value="yes">{{'form_answers.yes' | translate}}</nx-radio></div>
              <div nxCol="12,6"><nx-radio value="no">{{'form_answers.no' | translate}}</nx-radio></div>
            </div>
          </div>
        </nx-radio-group>
      </app-question>
    </ng-container>
  </ng-container>
</ng-template>
@if(!inMail) {
<div>
  <app-form-step icon="product-apartment-building">
    <ng-container *ngTemplateOutlet="questions"></ng-container>
    <div class="action-buttons">
      <button nxButton="secondary" role="button" nxStepperPrevious>
        Zurück
      </button>
      <button nxButton role="button" (click)="onSubmitClick()">
        Zur Auswertung
      </button>
    </div>
  </app-form-step>
</div>
} @else {
<ng-container *ngTemplateOutlet="questions"></ng-container>
}
