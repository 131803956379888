import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ComponentLocalTranslateService } from "../../core/services/local-translate-service/component-local-translate.service";
import { SourceResult } from "../../core/services/sources/sources.service";
import { TrackingService } from "../../core/services/trackingservice/tracking.service";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";
import { LiabilityInsuranceComponent } from "src/app/modules/insurances/liability-insurance/liability-insurance.component";
import { CyberInsuranceComponent } from "src/app/modules/insurances/cyber-insurance/cyber-insurance.component";
import { MaterialInsuranceComponent } from "src/app/modules/insurances/material-insurance/material-insurance.component";
import { GeneralInsuranceComponent } from "src/app/modules/insurances/general-insurance/general-insurance.component";
import { BuildingInsuranceComponent } from "src/app/modules/insurances/building-insurance/building-insurance.component";
import { ConstructionInsuranceComponent } from "src/app/modules/insurances/construction-insurance/construction-insurance.component";
import { TechnologyInsuranceComponent } from "src/app/modules/insurances/technology-insurance/technology-insurance.component";
import { TransportInsuranceComponent } from "src/app/modules/insurances/transport-insurance/transport-insurance.component";
import { LegalInsuranceComponent } from "src/app/modules/insurances/legal-insurance/legal-insurance.component";
import { FleetInsuranceComponent } from "src/app/modules/insurances/fleet-insurance/fleet-insurance.component";
import { OccupationInsuranceComponent } from "src/app/modules/insurances/occupation-insurance/occupation-insurance.component";
import { SicknessInsuranceComponent } from "src/app/modules/insurances/sickness-insurance/sickness-insurance.component";
import { AccidentInsuranceComponent } from "src/app/modules/insurances/accident-insurance/accident-insurance.component";
import { Step1Component } from "src/app/modules/advisor-form/steps/step1/step1.component";
import { Step2Component } from "src/app/modules/advisor-form/steps/step2/step2.component";
import { Step3Component } from "src/app/modules/advisor-form/steps/step3/step3.component";

@Component({
  selector: "app-new-lead",
  templateUrl: "./new-lead.component.html",
  styleUrls: ["./new-lead.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: TranslateService, useClass: ComponentLocalTranslateService },
  ],
  imports: [
    TranslateModule,
    LiabilityInsuranceComponent,
    CyberInsuranceComponent,
    MaterialInsuranceComponent,
    GeneralInsuranceComponent,
    BuildingInsuranceComponent,
    ConstructionInsuranceComponent,
    TechnologyInsuranceComponent,
    TransportInsuranceComponent,
    LegalInsuranceComponent,
    FleetInsuranceComponent,
    OccupationInsuranceComponent,
    SicknessInsuranceComponent,
    AccidentInsuranceComponent,
    Step1Component,
    Step2Component,
    Step3Component,
  ],
  preserveWhitespaces: true,
  standalone: true,
})
export class NewLeadComponent implements OnInit {
  @Input() name!: string;
  @Input() zipCode!: string;
  @Input() emailAddress!: string;
  @Input() phoneNumber!: string;
  @Input() availability!: string;
  @Input() isCustomer!: string;
  @Input() source!: SourceResult;
  @Input() quelle!: string;
  @Input() lang?: string;
  @Input() customerLang!: string;
  @Input() leadApp!: string;
  @Input() leadDate!: string;
  @Input() campaign!: string;
  @Input() environment!: string;
  insuranceResult: {
    mandatory: string[];
    optional: string[];
  } = { mandatory: [], optional: [] };

  // Application specific information for feedback system
  private _starRating = 4;
  public get starRating() {
    if (this._starRating < 3 && this.starRating > 5) {
      const clampedValue = this._starRating < 3 ? 3 : 5;
      console.debug(
        "Star rating has unexpected value, valid ratings values are: 3, 4, 5. Value clamped to",
        clampedValue,
      );
      return clampedValue;
    }
    return this._starRating;
  }
  private _appName = "allianz-leadform";
  public get feedbackUrlNegative() {
    return this.feedbackUrl(FeedbackType.Negative);
  }
  public get feedbackUrlMedium() {
    return this.feedbackUrl(FeedbackType.Medium);
  }
  public get feedbackUrlPositive() {
    return this.feedbackUrl(FeedbackType.Positive);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private trackingService: TrackingService,
    private calculator: NeedformCalculatorService,
  ) {}

  ngOnInit(): void {
    if (this.lang) this.translateService.use(this.lang.toLowerCase());
    else {
      // default language
      this.translateService.use("de");
    }
    this.insuranceResult = this.calculator.getResult();
  }

  /**
   * Trusts unsafe html and renders it without sanitization
   * WARNING: Do not use unless absolutely necessary.
   * Using it may expose application to XSS attacks.
   * It should only be used on trusted input.
   **/
  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /**
   * Generates feedback url where the appName and startRating are configurable in component
   * Includes also additional items about the lead when available
   * @param feedbackType Feedback type
   * @returns feedback url
   */
  private feedbackUrl(feedbackType: FeedbackType): string {
    let url = `https://www.allianz.ch/de/ldp/lead-bewertung-kb.html?leads=leadScore&lApp=${this._appName}&lAr=${this._starRating}`;
    if (this.trackingService.ls_gaID !== "")
      url = url + "&lGa=" + this.trackingService.ls_gaID;
    if (this.trackingService.ls_campaign !== "")
      url = url + "&lCp=" + this.trackingService.ls_campaign;
    if (this.trackingService.ls_kunde !== "")
      url = url + "&lKn=" + this.trackingService.ls_kunde;
    if (this.trackingService.ls_src !== "")
      url = url + "&lSc=" + this.trackingService.ls_src;
    if (this.trackingService.ls_werber !== "")
      url = url + "&lKb=" + this.trackingService.ls_werber;
    url = url + "&lKr=" + feedbackType;

    return url;
  }
}

enum FeedbackType {
  Negative = 1,
  Medium,
  Positive,
}
