<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Rechtsschutzversicherung der CAP" [titleOnly]="inMail">
      Als Unternehmerin oder Unternehmer kennen Sie die Risiken, die mit Internationalisierung, vernetzten Märkten und
      immer neuen Gesetzen einhergehen. Erfahrene Anwältinnen und Anwälte der <strong>CAP
        Rechtsschutzversicherung</strong> helfen bei schwierigen Fragen und nehmen Ihnen im Falle eines Rechtsstreits
      die Arbeit und den Ärger ab. Und sie sorgen auch dafür, dass er nicht noch schwerwiegende finanzielle Folgen hat
      oder Ihnen gar den Mut nimmt.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance de protection juridique de la CAP" [titleOnly]="inMail">
      En tant qu’entrepreneur, vous connaissez les risques inhérents à l’internationalisation, aux marchés
      interconnectés et au nombre croissant de nouvelles lois. Les avocats expérimentés de la <strong>CAP Protection
        Juridique</strong> sont à vos côtés, vous aident si vous avez des questions difficiles et vous déchargent des
      formalités et tracas administratifs en cas de litige. Ils font aussi en sorte que la situation n'ait pas en plus
      de lourdes conséquences financières ou qu'elle vous décourage complètement.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione di protezione giuridica di CAP" [titleOnly]="inMail">
      Come imprenditori, conoscete bene i rischi legati alla globalizzazione, ai mercati interconnessi e alla revisione
      continua del quadro normativo. L’<strong>assicurazione di protezione giuridica CAP</strong> vi offre l’assistenza
      di avvocati esperti in casi complessi, facendovi risparmiare lavoro e seccature in caso di controversie legali e
      consentendovi di evitare pesanti conseguenze finanziarie o di perdere la motivazione.
    </app-result-item>
  </app-translation>
</nx-accordion>
