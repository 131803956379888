<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Sachversicherung" [titleOnly]="inMail">
      Ihre Produktionshalle steht in Flammen. Ihr Büro unter Wasser. Oder Ihr Lager wurde ausgeräumt. Unsere
      <strong>Sachversicherung</strong> schützt Ihr Inventar und Ihre Waren. Und bei einem Betriebsunterbruch auch Ihre
      Erträge.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance de choses" [titleOnly]="inMail">
      Il y a le feu dans votre hall de production, votre bureau est inondé, ou votre entrepôt a été vidé? Notre
      <strong>assurance de choses</strong> protège votre inventaire et vos marchandises. Et en cas d'interruption
      d'activité, également vos revenus.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione di cose" [titleOnly]="inMail">
      Il vostro stabilimento di produzione è in fiamme. L’ufficio è sommerso dall’acqua. Oppure vi hanno “ripulito” il
      magazzino. L’<strong>assicurazione di cose</strong> protegge il vostro inventario e la vostra merce. E, in caso di
      interruzione di esercizio, anche i vostri proventi.
    </app-result-item>
  </app-translation>
</nx-accordion>
