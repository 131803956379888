<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Flottenversicherung" [titleOnly]="inMail">
      Wo viel gefahren wird, kann auch viel passieren. Unsere <strong>Flottenversicherung</strong> schützt die komplette
      Fahrzeugflotte Ihres Unternehmens. Flexibel, massgeschneidert und unkompliziert. Je nach Grösse und
      Sicherheitsbedürfnis vereinbaren wir eine fixe Prämie oder ein Bonus-Malus-System. In beiden Fällen ist Ihr
      Fuhrpark umfassend versichert. Zusätzlich können Sie von unseren Fleet-Efficiency-Services profitieren.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance de flottes" [titleOnly]="inMail">
      Qui roule beaucoup, risque aussi beaucoup. Notre <strong>assurance de flottes</strong> protège l'ensemble du parc
      de véhicules de votre entreprise. De manière flexible, sur mesure et simple. En fonction de la taille de la flotte
      et des besoins de sécurité, nous convenons une prime fixe ou un système de bonus-malus. Dans les deux cas, votre
      parc de véhicules est assuré de manière globale. Vous pouvez par ailleurs bénéficier de nos services Fleet
      Efficiency.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione flotte" [titleOnly]="inMail">
      Chi macina tanti chilometri ne vede di tutti i colori. La nostra <strong>assicurazione flotte</strong> tutela
      l’intero parco veicoli della vostra azienda in modo semplice, flessibile e personalizzato. In base al volume della
      flotta e alle esigenze specifiche dell’azienda in tema di sicurezza concordiamo un premio fisso o un sistema
      bonus-malus. In entrambi i casi, il parco veicoli è assicurato al 100%. E in più potete approfittare dei nostri
      servizi Fleet Efficiency.
    </app-result-item>
  </app-translation>
</nx-accordion>
