<ng-template #questions>
  <ng-container [formGroup]="needformFormGroup">
    <ng-container formGroupName="company">
      <app-question path="company.companyName" [inMail]=inMail [translateAnswer]="false">
        <ng-container question>Wie heisst Ihre Firma?</ng-container>
        <nx-formfield label="Firmenname" appearance="outline" floatLabel="always">
          <input nxInput formControlName="companyName" />
        </nx-formfield>
      </app-question>
      <app-question path="company.companyLegalForm" [inMail]=inMail [translateAnswer]="false">
        <ng-container question>Welche Rechtsform hat Ihr Unternehmen?</ng-container>
        <nx-formfield label="Rechtsform" appearance="outline" floatLabel="always">
          <nx-dropdown formControlName="companyLegalForm">
            <nx-dropdown-item value="AG">AG</nx-dropdown-item>
            <nx-dropdown-item value="Einzelfirma">Einzelfirma</nx-dropdown-item>
            <nx-dropdown-item value="GmbH">GmbH</nx-dropdown-item>
            <nx-dropdown-item value="Verein">Verein</nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </app-question>
      <app-question path="company.foundingYear" [inMail]=inMail [translateAnswer]="false">
        <ng-container question>In welchem Jahr wurde Ihr Unternehmen gegründet?</ng-container>
        <nx-formfield label="Gründungsjahr" appearance="outline" floatLabel="always">
          <input nxInput type="number" formControlName="foundingYear" />
          <!-- <input nxDatefield [datepicker]="datepicker" nxInput type="number" formControlName="foundingYear"/> -->
          <!-- <nx-datepicker-toggle -->
          <!--   [for]="datepicker" -->
          <!--   nxFormfieldSuffix -->
          <!-- ></nx-datepicker-toggle> -->
          <!-- <nx-datepicker #datepicker startView="multi-year"></nx-datepicker> -->
        </nx-formfield>
      </app-question>
      <app-question path="company.industry" [inMail]=inMail [translateAnswer]="false">
        <ng-container question>In welcher Branche ist Ihr Unternehmen tätig?</ng-container>
        <nx-formfield label="Branche" appearance="outline" floatLabel="always">
          <nx-dropdown formControlName="industry">
            <nx-dropdown-item value="Land- und Forstwirtschaft inkl. Fischerei">Land- und Forstwirtschaft inkl.
              Fischerei</nx-dropdown-item>
            <nx-dropdown-item value="Herstellung und Verarbeitung">Herstellung und Verarbeitung</nx-dropdown-item>
            <nx-dropdown-item value="Energie- und Wasserversorgung">Energie- und Wasserversorgung</nx-dropdown-item>
            <nx-dropdown-item value="Baugewerbe">Baugewerbe</nx-dropdown-item>
            <nx-dropdown-item value="Motorfahrzeuggewerbe">Motorfahrzeuggewerbe</nx-dropdown-item>
            <nx-dropdown-item value="Detailhandel">Detailhandel</nx-dropdown-item>
            <nx-dropdown-item value="Grosshandel">Grosshandel</nx-dropdown-item>
            <nx-dropdown-item value="Transport und Lagerei">Transport und Lagerei</nx-dropdown-item>
            <nx-dropdown-item value="Gastgewerbe und Beherbergung">Gastgewerbe und Beherbergung</nx-dropdown-item>
            <nx-dropdown-item value="Information, Kommunikation und Medien">Information, Kommunikation und
              Medien</nx-dropdown-item>
            <nx-dropdown-item value="Finanz- und Versicherungswesen und Beratung">Finanz- und Versicherungswesen und
              Beratung</nx-dropdown-item>
            <nx-dropdown-item value="Grundstück- und Wohnungswesen, ">Grundstück- und Wohnungswesen, </nx-dropdown-item>
            <nx-dropdown-item value="Gesundheits- und Sozialwesen">Gesundheits- und Sozialwesen</nx-dropdown-item>
            <nx-dropdown-item value="Beauty, Kunst">Beauty, Kunst</nx-dropdown-item>
            <nx-dropdown-item value="Unterhaltung, Sport und Erholung">Unterhaltung, Sport und
              Erholung</nx-dropdown-item>
            <nx-dropdown-item value="Erziehung und Unterricht">Erziehung und Unterricht</nx-dropdown-item>
            <nx-dropdown-item value="Sonstige Dienstleistungen">Sonstige Dienstleistungen</nx-dropdown-item>
            <nx-dropdown-item value="keine dieser Branchen">keine dieser Branchen</nx-dropdown-item>
          </nx-dropdown>
        </nx-formfield>
      </app-question>
    </ng-container>
  </ng-container>
</ng-template>
@if(!inMail) {
<div>
  <app-form-step icon="product-bank-monument-info">
    <ng-container *ngTemplateOutlet="questions"></ng-container>
    <div class="action-buttons">
      <button nxButton="secondary" role="button" nxStepperPrevious>
        Zurück
      </button>
      <button nxButton role="button" nxStepperNext>Weiter</button>
    </div>
  </app-form-step>
</div>
} @else {
<ng-container *ngTemplateOutlet="questions"></ng-container>
}
