import { NgTemplateOutlet } from "@angular/common";
import { Component, Input, output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { TranslateModule } from "@ngx-translate/core";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";
import { FormStepComponent } from "src/app/modules/form-step/form-step.component";
import { QuestionComponent } from "src/app/modules/question/question.component";

@Component({
  selector: "app-step3",
  standalone: true,
  imports: [
    FormStepComponent,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    ReactiveFormsModule,
    NxRadioModule,
    NxButtonModule,
    NxProgressStepperModule,
    NxGridModule,
    QuestionComponent,
    NgTemplateOutlet,
    TranslateModule,
  ],
  templateUrl: "./step3.component.html",
  styleUrl: "./step3.component.css",
})
export class Step3Component {
  @Input() inMail = false;
  needformFormGroup;
  submit = output();

  constructor(calculator: NeedformCalculatorService) {
    this.needformFormGroup = calculator.getFormGroup();
  }

  onSubmitClick() {
    this.submit.emit();
  }
}
