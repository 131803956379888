<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Cyber-Risk-Versicherung" [titleOnly]="inMail">
      Ihr Unternehmen steckt mitten im technologischen Wandel? Die Digitalisierung hält mehr und mehr Einzug? Mit den
      Chancen für Ihr Geschäft wachsen auch die Gefahren durch Cyberkriminalität. Daten und geistiges Eigentum geraten
      zunehmend ins Visier von Kriminellen. Unsere <strong>Cyberversicherung</strong> steht Ihnen auch dann zur Seite,
      wenn die Angriffe weit über Datenmissbrauch hinausgehen.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance Cyber Risk" [titleOnly]="inMail">
      Votre entreprise est en pleine évolution technologique? La digitalisation ne cesse de gagner du terrain? Les
      nouvelles opportunités qui se présentent pour vos activités s'accompagnent aussi de nouveaux risques liés à la
      cybercriminalité. Les données et la propriété intellectuelle constituent les cibles privilégiées des
      cybercriminels. Notre <strong>cyberassurance</strong> vous soutient également en cas d’attaques qui dépassent
      largement l’utilisation abusive de données.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione Cyber Risk" [titleOnly]="inMail">
      La vostra impresa sta attraversando una fase di cambiamento tecnologico? La digitalizzazione si fa sentire sempre
      di più? Con le nuove opportunità per le imprese, crescono anche i rischi legati alla criminalità informatica, che
      prende sempre più di mira i dati e la proprietà intellettuale. La nostra <strong>assicurazione Cyber Risk</strong>
      assiste la vostra impresa anche quando i rischi si spingono ben oltre l’utilizzo illecito dei dati.
    </app-result-item>
  </app-translation>
</nx-accordion>
