import { Component, Input } from "@angular/core";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";

@Component({
  selector: "app-result-item",
  standalone: true,
  imports: [NxAccordionModule],
  templateUrl: "./result-item.component.html",
  styleUrl: "./result-item.component.scss",
})
export class ResultItemComponent {
  @Input({ required: true }) title!: string;
  @Input() expanded: boolean = false;
  @Input() titleOnly = false;
}
