import { Component, ElementRef, ViewChild } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { NxButtonModule } from "@aposin/ng-aquila/button";
import {
  NxDatefieldModule,
  NxNativeDateModule,
} from "@aposin/ng-aquila/datefield";
import { NxDropdownModule } from "@aposin/ng-aquila/dropdown";
import { NxFormfieldModule } from "@aposin/ng-aquila/formfield";
import { NxGridModule } from "@aposin/ng-aquila/grid";
import { NxInputModule } from "@aposin/ng-aquila/input";
import { NxProgressStepperModule } from "@aposin/ng-aquila/progress-stepper";
import { NxRadioModule } from "@aposin/ng-aquila/radio-button";
import { NeedformCalculatorService } from "src/app/app/core/services/needform-calculator.service";
import { Step1Component } from "./steps/step1/step1.component";
import { Step2Component } from "./steps/step2/step2.component";
import { Step3Component } from "./steps/step3/step3.component";
import { StepperSelectionEvent } from "@angular/cdk/stepper";

@Component({
  selector: "app-advisor-form",
  standalone: true,
  imports: [
    NxProgressStepperModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxDropdownModule,
    NxDatefieldModule,
    NxNativeDateModule,
    NxButtonModule,
    NxRadioModule,
    ReactiveFormsModule,
    Step1Component,
    Step2Component,
    Step3Component,
  ],
  templateUrl: "./advisor-form.component.html",
  styleUrl: "./advisor-form.component.scss",
})
export class AdvisorFormComponent {
  needformFormGroup;

  @ViewChild("self", { read: ElementRef })
  private self?: ElementRef<HTMLDivElement>;
  constructor(
    private router: Router,
    calculator: NeedformCalculatorService,
  ) {
    this.needformFormGroup = calculator.getFormGroup();
  }
  submitForm() {
    this.router.navigate(["results"]);
  }

  stepSelectionChange(_e: StepperSelectionEvent) {
    if (this.self) {
      this.self.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
  }
}
