import { Component } from "@angular/core";
import { BaseInsuranceComponent } from "../base/base.component";
import { NxAccordionModule } from "@aposin/ng-aquila/accordion";
import { ResultItemComponent } from "../../result-item/result-item.component";
import { TranslationComponent } from "../../translation/translation.component";

@Component({
  selector: "app-liability-insurance",
  standalone: true,
  imports: [NxAccordionModule, ResultItemComponent, TranslationComponent],
  templateUrl: "./liability-insurance.component.html",
  styleUrl: "./liability-insurance.component.css",
})
export class LiabilityInsuranceComponent extends BaseInsuranceComponent {}
