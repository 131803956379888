<nx-accordion #content [multi]="true" [variant]="optional?'light':'regular'">
  <app-translation lang="de">
    <app-result-item title="Transportversicherung" [titleOnly]="inMail">
      Der Transport von Waren ist oft sehr komplex. Und gerade wenn mehrere Unternehmen beteiligt sind, steigt das
      Risiko von Beschädigungen und Diebstahl unterwegs. Gut, gibt es unsere <strong>Transportversicherung</strong>. Sie
      bietet massgeschneiderte Lösungen für Frachtführer und Spediteure, die Waren transportieren oder transportieren
      lassen – und dabei ihren Kundinnen und Kunden die nötige Sicherheit garantieren wollen.
    </app-result-item>
  </app-translation>
  <app-translation lang="fr">
    <app-result-item title="Assurance transport" [titleOnly]="inMail">
      Le transport de marchandises est souvent une opération complexe. Et lorsque plusieurs entreprises sont impliquées,
      le risque de dégradation et de vol au cours du trajet augmente. C’est là qu’intervient notre <strong>assurance
        transport</strong>. Elle offre des solutions sur mesure aux transporteurs et aux transitaires qui transportent
      ou font transporter des marchandises – et souhaitent garantir la sécurité nécessaire à leurs clients.
    </app-result-item>
  </app-translation>
  <app-translation lang="it">
    <app-result-item title="Assicurazione trasporti" [titleOnly]="inMail">
      Il trasporto di merci è spesso molto complesso. Quando coinvolge più imprese, poi, il rischio di furti o
      danneggiamenti durante il tragitto aumenta. Per fortuna c’è la nostra <strong>assicurazione trasporti</strong>,
      che offre soluzioni personalizzate per vettori e spedizionieri che trasportano, o fanno trasportare, merci e
      vogliono garantire ai propri clienti tutta la sicurezza necessaria.
    </app-result-item>
  </app-translation>
</nx-accordion>
