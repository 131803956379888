@if (!inMail) {
<h3><ng-container *ngTemplateOutlet="title"></ng-container></h3>
<ng-content></ng-content>
} @else {
<tr>
  <td><ng-container *ngTemplateOutlet="title"></ng-container></td>
  <td>@if (translateAnswer) { {{'form_answers.' + answer | translate}} } @else { {{answer}} }</td>
</tr>
}

<ng-template #title>
  <ng-content select="[question]"></ng-content>
</ng-template>
